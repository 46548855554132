/* You can add global styles to this file, and also import other style files
  
.ngx-datatable .datatable-header {
    background-color: rgb(101, 175, 92)!important;
    padding-left: 20px;
    color: white;
    font-weight: bold;
    padding-left: 20px;
    padding-top: 5px;
    max-height: 50px;
}
.ngx-datatable .datatable-footer {
    background-color:  rgb(101, 175, 92)!important;
    padding-left: 20px;
    color: white;
    font-weight: bold;
   
}
.ngx-datatable .datatable-body .datatable-row-wrapper {
    padding-left: 20px;
    padding-top: 2px;
} */

@media screen and (min-width: 762px) {
    .input-group-width {
        width: 58%
    }
}